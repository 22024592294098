class Collapsing_card extends HTMLDivElement {
    constructor() {
        super();
        this.selfFilled = false;
        this.loaded = false;
        $(document).on('turbolinks:load', () => {
            if (this.loaded)
                return;
            let newElement = false;
            this.actionBtn = this.querySelector(".collapse-btn-filled") || (() => {
                this.selfFilled = true;
                const box = this.querySelector(".collapse-btn") ||
                    (newElement = true) && document.createElement('a');
                box.style.padding = "0 10px";
                box.style.userSelect = "none";
                return box;
            })();
            this.actionBtn.tabIndex = 0;
            this.actionBtn.style.cursor = "pointer";
            newElement && (this.actionBtn.style.float = "right");
            newElement && this.findOrDie(".card-header").appendChild(this.actionBtn);
            this.headerElement = this.querySelector(".card-header");
            if (this.headerElement) {
                ["a", "input", "button"].forEach(selector => {
                    var _a;
                    (_a = this.headerElement) === null || _a === void 0 ? void 0 : _a.querySelectorAll(selector).forEach(tag => tag.addEventListener("click", (e) => e.stopPropagation()));
                });
                this.headerElement.style.cursor = "pointer";
                this.headerElement.addEventListener("click", () => this.clickCallback());
            }
            this.actionBtn.addEventListener("click", (event) => {
                event.stopPropagation();
                this.clickCallback();
            });
            this.loaded = true;
            this.attributeChangedCallback("closed", null, this.getAttribute("closed"));
        });
    }
    clickCallback() {
        this.toggleAttribute('closed');
    }
    open() {
        if (this.selfFilled) {
            this.actionBtn.innerHTML = "";
            this.actionBtn.appendChild(Collapsing_card.closeIcon.cloneNode());
        }
        this.querySelectorAll(".hide-if-closed").forEach(e => e.style.display = "");
        this.findOrDie(".card-body").style.display = "block";
    }
    close() {
        if (this.selfFilled) {
            this.actionBtn.innerHTML = "";
            this.actionBtn.appendChild(Collapsing_card.openIcon.cloneNode());
        }
        this.querySelectorAll(".hide-if-closed").forEach(e => e.style.display = "none");
        this.findOrDie(".card-body").style.display = "none";
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.loaded)
            return;
        if (name == 'closed')
            newValue == null ? this.open() : this.close();
    }
    static get observedAttributes() {
        return ['closed'];
    }
}
Collapsing_card.openIcon = (() => {
    const res = document.createElement('i');
    res.classList.add("fas", "fa-solid", "fa-caret-down");
    return res;
})();
Collapsing_card.closeIcon = (() => {
    const res = document.createElement('i');
    res.classList.add("fas", "fa-solid", "fa-caret-up");
    return res;
})();
customElements.define('collapsing-card', Collapsing_card, { extends: 'div' });
