// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// require("openlayer")
// require("src/panzoom.min")
//require("src/custom")
require('admin-lte');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "bootstrap";
// for adminLTE but not working anymore since config/webpack/environnement.js modif $: 'jquery' => $: 'jquery/src/jquery', idesm ligne d apres
// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// });
import "@fortawesome/fontawesome-free/js/all";
import '../stylesheets/application'; // This file will contain your custom CSS
import './collapsing_card';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
const _ = [flatpickr, French];
Element.prototype.findOrDie = function (selectors) {
    const res = this.querySelector(selectors);
    if (res == null) {
        console.error(this);
        throw new Error(`\`${selectors}\` doesn't exist on ${this}`);
    }
    return res;
};
Document.prototype.findOrDie = Element.prototype.findOrDie;
DocumentFragment.prototype.findOrDie = Element.prototype.findOrDie;
